import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styles from './ariEventsModal.module.css';

interface AriEventsModalProps {
  showModal: boolean;
  selectedAriEvent: { siteId: string; orderId: string } | undefined;
  onAriEventAction: (decision: 'ACCEPTED' | 'DENIED') => void;
  patchAriEventErrorMessage: string | null;
}

const AriEventsModal: React.FC<AriEventsModalProps> = ({
                                                         showModal,
                                                         selectedAriEvent,
                                                         onAriEventAction,
                                                         patchAriEventErrorMessage,
                                                       }) => {
  if (!selectedAriEvent || !showModal) return null;

  return (
      <Modal
          open={showModal}
          basic
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className={styles.mainModal}
      >
        <Modal.Content>
          <div className={[styles.ariEventBorder, styles.modalContentContainer].join(' ')}>
            <div className={styles.ariEventTitleBackground}>
            <span className={styles.ariEventHeaderText}>
              <Icon name='exclamation triangle' />
              AGE VERIFICATION REQUIRED ON SMCO
            </span>
            </div>

            {/* Display error message */}
            <div className={styles.ariEventErrorTextContainer}>
            <span className={[styles.ariEventText, styles.ariEventErrorText].join(' ')}>
              {patchAriEventErrorMessage ? patchAriEventErrorMessage : null}
            </span>
            </div>

            <div className={styles.buttonsRow}>
              <Button
                  className={`${styles.button} ${styles.acceptButton}`}
                  circular
                  color='green'
                  onClick={() => onAriEventAction('ACCEPTED')}
              >
                ACCEPT
              </Button>
              <Button
                  className={`${styles.button} ${styles.denyButton}`}
                  circular
                  color='red'
                  onClick={() => onAriEventAction('DENIED')}
              >
                DENY
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
  );
};

export default AriEventsModal;
